import React from "react"
import Layout from "../../components/layout"
import { Container, Wrapper } from "../../theme"
import {
  ServicesRow,
  Process,
  ServiceExamples,
  ContentGrid,
  IconRow,
  TwoColumns,
} from "../../components"
import { Colors } from "../../theme/styles"

// Will in center of page meet our CTO

const ProductDevelopment = () => {
  return (
    <Layout title="Web and Mobile App Development" type="app">
      <Wrapper white>
        <Container>
          <IconRow type="app" />
        </Container>
        <ServicesRow
          serviceTitle="Product Design and Development to Accelerate Your Business"
          subtitle="High performing web and mobile applications to deliver the product of your dreams"
          category="Our App Development Services"
          service="apps"
          paragraph1="The world continues to see a rapid change in its business environment and every company must adapt to become a technology company. It's no longer a luxury, but a necessity for companies to think about and develop its own set of products and platforms."
          paragraph2="We make sure we use an optimal mix of both next-gen technology and design to help you build products you're proud of. Our focus is to build products and platforms that are customer-centric, scalable, and that are aligned with your strategic vision to build revenue and enhance the user experience."
          paragraph3="It's our priority to work directly with our clients to ensure their MVP, product iteration, feature integration, and more is developed and designed with their company goals in mind. "
        />
        <ContentGrid
          type="techServices"
          background={Colors.white}
          color={Colors.black}
          seoTitle="Growth Driven Development To Enhance Your Business Goals"
        />
        <TwoColumns
          type="cto"
          background={Colors.lightBlue}
          color={Colors.white}
          title="Meet our CTO"
          paragraph1="William Whatley has over 5 years of experience in the technology world serving various different roles. 
        From concept to at-scale, he has worked with startups and
        enterprises in meeting and exceeding their ever-evolving technical
        objectives. Now working as CTO of CheshTech in a Fractional CTO role he helps ideate, strategize
        implementations, source engineers, manage project teams, architect
        and design systems, and oversee production environments."
          paragraph2="He has experience with big data, machine learning, native and web apps. Combined, he
        has designed, developed, deployed, and managed applications with
        over 90,000 users and $3M in ARR."
        />
        <Process type="technology" />
        <ServiceExamples
          service="apps"
          title="Products we've built"
          background={Colors.white}
        />
      </Wrapper>
    </Layout>
  )
}

export default ProductDevelopment
